import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { VCenter } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  ${VCenter}
  background-color: #f5f8fc;
  min-height: 326px;
  position: relative;

  @media only screen and (max-width: 767px) {
    overflow: hidden;
  }
`;

const ContactBannerLeft = styled.div`
  position: absolute;
  top: -58px;
  left: 130px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const ContactBannerRightTop = styled.div`
  position: absolute;
  right: 18%;
  top: -80px;

  @media only screen and (max-width: 767px) {
    right: -36%;
    top: -3px;
  }
`;

const ContactBannerRightBottom = styled.div`
  position: absolute;
  right: 24%;
  bottom: 0;

  @media only screen and (max-width: 767px) {
    left: -169px;
  }
`;

const ContactBannerContent = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 342px;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  z-index: 999;

  h1 {
    color: #424242;
    line-height: 50px;
  }
`;

const SearchBox = styled.div`
  background-color: #fff;
  border: 1px solid #e3e8f0;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 7px 0 hsl(0deg 0% 53% / 30%);
  box-shadow: 0 2px 7px 0 hsl(0deg 0% 53% / 30%);
  padding: 3px 0 2px;
`;

const InputGroup = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  z-index: 9;

  input {
    background-color: #fff;
    border: 0;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    &:focus {
      box-shadow: none;
    }
  }
`;

const InputGroupPrepend = styled.div`
  margin-right: -1px;
  display: flex;
  background: none;
  border: 0;
  padding: 0;

  img {
    margin-left: 9px;
    margin-top: 2px;
    width: 20px;
  }
`;

const BannerSection = () => {
  const [input, setInput] = useState("");

  const HandleSearch = (event: any) => {
    setInput(event.target.value);
  };

  console.log(input);

  return (
    <SectionWrap id="c-banner">
      <ContactBannerLeft>
        <span className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
          <img src="/contact/contact-banner-left.png" alt="" loading="lazy" />
        </span>
      </ContactBannerLeft>
      <ContactBannerRightTop>
        <span className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
          <img
            src="/contact/contact-banner-right-top.png"
            alt=""
            loading="lazy"
          />
        </span>
      </ContactBannerRightTop>
      <ContactBannerRightBottom>
        <span className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
          <img
            src="/contact/contact-banner-right-bottom.png"
            alt=""
            loading="lazy"
          />
        </span>
      </ContactBannerRightBottom>
      <Container className="text-center">
        <ContactBannerContent>
          <h1>Find answers to common questions</h1>
        </ContactBannerContent>
        <Row className="justify-content-md-center">
          <Col md={6} style={{ position: "relative" }}>
            <SearchBox>
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  // TODO: do something with form values
                  navigate(
                    `https://help.charitableimpact.com/search?query=${input}`,
                    { replace: true }
                  );
                }}
              >
                <InputGroup>
                  <InputGroupPrepend className="input-group-text">
                    <img src="/icons/icon-search.svg" alt="" loading="lazy" />
                  </InputGroupPrepend>
                  <Form.Control
                    type="search"
                    placeholder="Search the Help Centre"
                    aria-describedby="button-addon2"
                    style={{ paddingTop: "8px" }}
                    onKeyDownCapture={HandleSearch}
                  />
                </InputGroup>
              </Form>
            </SearchBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default BannerSection;
