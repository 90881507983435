import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ListIcons from '../ListIcons/ListIcons';
import { StyledListGroupItem, StyledPara } from './GetIntouchSection';

const SectionWrap = styled.section`
  margin-bottom: 100px;

  @media only screen and (max-width: 767px) {
    background-color: #f5f8fc;
  }
`;

const StyledH2 = styled.h2`
  color: #282828;
  margin: 0 0 27px;

  @media only screen and (max-width: 767px) {
    margin: -60px 0 0;
    text-align: center;
  }
`;

const OfficeAddress = styled.div`
  background-color: #f5f8fc;
  margin: 0 0 20px;
  min-height: 328px;
  padding: 68px 80px;

  @media (min-width: 768px) and (max-width: 1024px) {
    min-height: auto;
    padding: 14px 30px 8px 38px;
  }

  @media only screen and (max-width: 767px) {
    margin: 15px 0 20px;
    padding: 30px 30px 5px;
    min-height: auto;
  }
`;

const Title = styled.div`
  color: #424242;
  font-family: ProximaNova-Semibold;
  font-size: 24px;
`;

const StyledHr = styled.hr`
  border: 0;
  border-top: 1px solid #979797;
  height: 0 !important;
  box-sizing: content-box;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
  background-color: transparent;
`;

const VancouverOfficeImg = styled.div`
  position: relative;

  @media only screen and (max-width: 767px) {
    padding-top: 45px;
    text-align: center;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &::before {
    background-color: #93ebb2;
    right: 58px;
    width: 23px;
  }

  &::after {
    background-color: #db0163;
    right: 0;
    width: 48px;
  }

  img {
    height: auto;
    max-width: 100%;
    padding-right: 90px;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 767px) {
      margin: auto;
      max-width: 320px;
      padding-right: 0;
      width: 100%;
    }
  }
`;

const BangaloreOffice = styled.div`
  @media only screen and (max-width: 767px) {
    padding: 20px 0 0;
  }
`;

const BangaloreOfficeWrapper = styled.div`
  border-radius: 4px;
  color: #fff;
  padding: 0;
  position: relative;

  &::before {
    background-image: url(/contact/bangalore-office-dot.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -29px;
    content: '';
    left: -38px;
    position: absolute;
    top: 20px;
    width: 174px;
    z-index: -1;
  }
`;

const BangaloreOfficeImage = styled.div`
  position: relative;

  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  &::before,
  &::after {
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &::before {
    background-image: url(/contact/bar.png);
    width: 82px;
  }

  img {
    height: auto;
    max-width: 100%;
    padding-left: 90px;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 767px) {
      margin: auto;
      max-width: 320px;
      padding-left: 0;
    }
  }
`;

const OurOfficesSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-md-center'>
          <Col md={12} lg={10}>
            <StyledH2>Our offices</StyledH2>
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={7} className='order-2 order-md-1'>
            <OfficeAddress>
              <Title>Vancouver, Canada</Title>
              <div>
                <ListGroup as='ul'>
                  <StyledListGroupItem as='li' className='street'>
                    <a
                      href='https://goo.gl/maps/wvica4LKUB6HdKJj6'
                      target='_blank'>
                      <ListIcons className='mapmarker' />
                      #1250 - 1500 W Georgia St Vancouver, BC V6G 2Z6 Canada
                    </a>
                  </StyledListGroupItem>
                </ListGroup>
              </div>
              <StyledHr />
              <StyledPara>
                Our head office is located in Vancouver on the unceded and
                traditional territory belonging to the Musqueam, Squamish, and
                Tsleil-Waututh First Nations.
              </StyledPara>
            </OfficeAddress>
          </Col>

          <Col md={6} lg={5} className='ps-md-0 order-1 order-md-2'>
            <VancouverOfficeImg>
              <img
                src='/contact/vancouver.jpg'
                className='img-fluid'
                alt=''
                loading='lazy'
              />
            </VancouverOfficeImg>
          </Col>
        </Row>

        <BangaloreOffice>
          <BangaloreOfficeWrapper>
            <Row>
              <Col md={5} lg={5} className='pe-md-0'>
                <BangaloreOfficeImage>
                  <img
                    src='/contact/bengaluru.jpg'
                    className='img-fluid'
                    alt=''
                    loading='lazy'
                  />
                </BangaloreOfficeImage>
              </Col>

              <Col md={7} lg={7} className='pe-md-0'>
                <OfficeAddress>
                  <Title>Bengaluru, India</Title>
                  <div>
                    <ListGroup as='ul'>
                      <StyledListGroupItem as='li' className='street'>
                        <a
                          href='https://maps.app.goo.gl/HWAvExh4ctb86pwL9'
                          target='_blank'>
                          <ListIcons className='mapmarker' />
                          Infogain India Private Limited
                          <br />
                          Fortune Scion Business Centre
                          <br />
                          #90B, West Avenue 9,
                          <br />
                          Electronics City Phase 1<br />
                          Bengaluru – 560100, Karnataka, India
                        </a>
                      </StyledListGroupItem>
                    </ListGroup>
                  </div>
                  <StyledHr />
                </OfficeAddress>
              </Col>
            </Row>
          </BangaloreOfficeWrapper>
        </BangaloreOffice>
      </Container>
    </SectionWrap>
  );
};

export default OurOfficesSection;
