import React from 'react';
import styled from 'styled-components';

interface Props {
  className: string;
}

const ListIcon = styled.i`
  margin-right: 14px;
  vertical-align: middle;
  display: inline-block;

  &::before {
    background-size: cover;
    content: '';
    display: block;
  }

  &.mail {
    &::before {
      background-image: url(/icons/icon-mail.png);
      height: 15px;
      width: 19px;
    }
  }

  &.phone {
    &::before {
      background-image: url(/icons/phone.png);
      height: 19px;
      width: 19px;
    }
  }

  &.mapmarker {
    &::before {
      background-image: url(/icons/icon-map-pinpoint.png);
      height: 22.04px;
      width: 15.5px;
    }
  }

  &.mapmarker-small {
    &::before {
      background-image: url(/icons/icon-map-pinpoint-small.png);
      width: 14.36px;
      height: 16px;
    }
  }

  &.right-arrow {
    &::before {
      background-image: url(/icons/icon-arrow-right.png);
      height: 21.5px;
      width: 11.5px;
    }
  }
`;

const ListIcons: React.FC<Props> = ({ className }) => {
  return <ListIcon className={className} />;
};

export default ListIcons;
