import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ListIcons from '../ListIcons/ListIcons';
import { Script, ScriptStrategy } from 'gatsby';

const SectionWrap = styled.section`
  margin: 50px 0 120px;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const StyledH2 = styled.h2`
  color: #282828;
`;

export const StyledPara = styled.p`
  color: #282828;
  font-size: 16px;
  line-height: 24px;
`;

const BoldText = styled.span`
  font-family: ProximaNova-Medium;
  font-weight: 300;
`;

export const StyledListGroupItem = styled(ListGroup.Item)`
  border: 0 solid transparent;
  padding: 5px 0;
  list-style: none;

  a {
    color: #424242 !important;
    font-size: 16px;
  }
`;

const ChatOnlineBg = styled.div`
  background-color: #055ce5;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  margin: 25px 0 0;
  padding: 22px 0 21px 34px;
  cursor: pointer;
  position: relative;
  text-align: left;
`;

const ChatOnlineImg = styled.div`
  background-image: url(/contact/chat-oline-img.png);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: -31px;
  height: 140px;
  position: absolute;
  right: -13px;
  width: 160px;
`;

const ContactImage = styled.div`
  margin: 42px 0 0 79px;
  text-align: right;
  position: relative;
  width: 420px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #83e3e1;
    top: 50px;
    left: 50px;
    z-index: -2;
  }

  img {
    border-radius: 4px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 42px 0 0 10px;
    width: 250px;
  }

  @media only screen and (max-width: 767px) {
    margin: 55px 0 0;
  }
`;

const GetIntouchSection = () => {
  return (
    <SectionWrap id='getInTouch'>
      <Container>
        <Row className='justify-content-md-center'>
          <Col md={12} lg={11} className='mt-5'>
            <Row>
              <Col md={6} lg={5}>
                <StyledH2>Get in touch</StyledH2>
                <StyledPara>
                  Your giving makes a big impact, and we're here to help if you
                  need it. Please get in touch if you have questions or want to
                  learn more about charitable giving.
                </StyledPara>
                <StyledPara>
                  <BoldText>Chat online or phone us</BoldText>
                  <br />
                  Monday to Friday, 9:00am to 5:00pm <br />
                  (Pacific Time), or send us an email anytime.
                </StyledPara>
                <div>
                  <ListGroup as='ul'>
                    <StyledListGroupItem as='li'>
                      <a
                        href='mailto:hello@charitableimpact.com'
                        target='_blank'>
                        <ListIcons className='mail' />
                        hello@charitableimpact.com
                      </a>
                    </StyledListGroupItem>
                    <StyledListGroupItem as='li'>
                      <a>
                        <ListIcons className='phone' />
                        toll-free: <BoldText>1-877-531-0580</BoldText>
                      </a>
                    </StyledListGroupItem>
                  </ListGroup>
                </div>
                <ChatOnlineBg id='chatCta'>
                  <div>
                    Chat online with our team
                    <ChatOnlineImg />
                  </div>
                </ChatOnlineBg>

                <Script strategy={ScriptStrategy.idle}>
                  {`
                    var chatCtaEl = document.getElementById("chatCta");
                    chatCtaEl.addEventListener("click", openChat, false);

                    function openChat() {
                      if(window && window.Beacon) {
                        Beacon('open'); Beacon('navigate', '/ask/chat/');
                      }
                    }
                  `}
                </Script>
              </Col>

              <Col md={6} lg={7} className='d-none d-md-block'>
                <ContactImage>
                  <img
                    src='/contact/contact-img.jpg'
                    className='img-fluid'
                    alt=''
                    loading='lazy'
                  />
                </ContactImage>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default GetIntouchSection;
