import React from "react";
import BannerSection from "../components/Contact/BannerSection";
import GetIntouchSection from "../components/Contact/GetIntouchSection";
import OurOfficesSection from "../components/Contact/OurOfficesSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/support",
  path: "/contact",
  title: "Contact us",
  description:
    "Reach out to our team by phone, email, and chat if you need support. We're here to help.",
};

const Contact = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <GetIntouchSection />
      <OurOfficesSection />
    </Layout>
  );
};

export default Contact;
